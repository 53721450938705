import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Main from '@templates/Main'
import TextBox from '@atoms/TextBox'
import TextNarrow from '@atoms/TextNarrow'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

import { termsOfUse } from '@content/termsOfUse'

const TermsPage = ({ data, transitionStatus }) => {
  const pageData = data.pageData

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Legal" center>
          <h2>Terms of use</h2>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <TextNarrow>
          <ReactMarkdown children={termsOfUse.content} />
        </TextNarrow>
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query TermsPageQuery {
    pageData: datoCmsTermsPage {
      seoMetaTags {
        tags
      }
    }
  }
`

export default TermsPage
